<template>
    <div class="container-404">
        <div class="centered-element">
            <svg width="492" height="303" viewBox="0 0 492 303" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M385.628 300.567C412.283 268.838 428.337 227.907 428.337 183.225C428.337 82.4168 346.616 0.695312 245.807 0.695312C144.999 0.695312 63.2771 82.4168 63.2771 183.225C63.2771 227.907 79.3315 268.838 105.986 300.567H385.628Z" fill="#E3F1FF"/>
                    <g opacity="0.15">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M318.307 132.528C318.108 132.765 317.978 133.052 317.933 133.358L312.066 173.29C311.956 174.038 312.364 174.767 313.059 175.064L318.307 177.314C318.388 177.348 318.47 177.376 318.552 177.397C318.774 177.455 318.964 177.637 318.964 177.867V190.184C318.964 190.645 319.337 191.018 319.797 191.018C320.257 191.018 320.63 190.645 320.63 190.184V166.85C320.63 166.849 320.63 166.847 320.63 166.846L320.63 130.908C320.63 130.519 320.145 130.342 319.895 130.64L318.307 132.528Z" fill="#A4A4A4"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M318.307 131.695C318.108 131.932 317.978 132.219 317.933 132.525L312.066 172.457C311.956 173.205 312.364 173.934 313.059 174.231L318.307 176.481C318.388 176.515 318.47 176.543 318.552 176.564C318.774 176.622 318.964 176.804 318.964 177.034V189.351C318.964 189.812 319.337 190.185 319.797 190.185C320.257 190.185 320.63 189.812 320.63 189.351V166.017C320.63 166.016 320.63 166.014 320.63 166.013L320.63 130.075C320.63 129.686 320.145 129.509 319.895 129.807L318.307 131.695Z" fill="#C4C4C4"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M271.054 219.776C271.359 219.83 271.672 219.799 271.96 219.684L309.475 204.8C310.178 204.521 310.605 203.804 310.516 203.053L309.839 197.383C309.829 197.296 309.812 197.211 309.79 197.13C309.728 196.908 309.791 196.654 309.99 196.539L320.658 190.379C321.057 190.149 321.193 189.64 320.963 189.241C320.733 188.842 320.223 188.706 319.825 188.936L307.388 196.116C307.376 196.123 307.363 196.13 307.351 196.137L268.489 218.574C268.152 218.768 268.242 219.277 268.625 219.345L271.054 219.776Z" fill="#A4A4A4"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M270.637 219.358C270.942 219.412 271.256 219.381 271.543 219.267L309.059 204.382C309.761 204.103 310.188 203.386 310.099 202.635L309.423 196.965C309.412 196.878 309.396 196.793 309.373 196.712C309.312 196.49 309.374 196.236 309.574 196.121L320.241 189.961C320.64 189.731 320.777 189.222 320.546 188.823C320.316 188.424 319.807 188.288 319.408 188.518L306.972 195.698C306.959 195.705 306.947 195.712 306.934 195.719L268.072 218.156C267.736 218.35 267.825 218.859 268.208 218.927L270.637 219.358Z" fill="#C4C4C4"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M370.78 217.765C370.674 217.474 370.49 217.218 370.247 217.026L338.599 191.98C338.006 191.511 337.171 191.499 336.566 191.952L331.994 195.373C331.923 195.426 331.858 195.483 331.799 195.543C331.638 195.707 331.386 195.78 331.186 195.665L320.519 189.506C320.12 189.276 319.611 189.413 319.38 189.811C319.15 190.21 319.287 190.72 319.685 190.95L339.894 202.617C339.904 202.623 339.914 202.628 339.924 202.634L371.021 220.588C371.358 220.782 371.754 220.45 371.621 220.085L370.78 217.765Z" fill="#A4A4A4"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M370.78 216.932C370.674 216.641 370.49 216.385 370.247 216.193L338.599 191.147C338.006 190.678 337.171 190.666 336.566 191.119L331.994 194.54C331.923 194.592 331.858 194.649 331.799 194.71C331.638 194.874 331.386 194.947 331.187 194.832L320.519 188.672C320.12 188.442 319.61 188.579 319.38 188.977C319.15 189.376 319.287 189.886 319.685 190.116L332.144 197.309C332.149 197.312 332.154 197.315 332.159 197.318L371.021 219.755C371.358 219.949 371.754 219.617 371.621 219.252L370.78 216.932Z" fill="#C4C4C4"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M321.939 192.726C323.13 192.218 323.964 191.036 323.964 189.66C323.964 187.819 322.472 186.326 320.63 186.326C318.789 186.326 317.297 187.819 317.297 189.66C317.297 191.036 318.131 192.218 319.321 192.726L312.713 302.999H328.548L321.939 192.726Z" fill="#A4A4A4"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M321.106 192.726C322.296 192.218 323.131 191.036 323.131 189.66C323.131 187.819 321.638 186.326 319.797 186.326C317.956 186.326 316.464 187.819 316.464 189.66C316.464 191.036 317.298 192.218 318.488 192.726L311.88 302.999H327.714L321.106 192.726Z" fill="#C4C4C4"/>
                    </g>
                    <g opacity="0.6">
                    <rect x="68.1445" y="149.675" width="32.5072" height="76.6587" rx="16.2536" fill="#E9EBF2"/>
                    <rect x="83.6704" y="180.727" width="1.94073" height="119.84" rx="0.970364" fill="#E1E2E5"/>
                    <rect x="91.6769" y="184.987" width="1.94073" height="16.3584" rx="0.970364" transform="rotate(30 91.6769 184.987)" fill="#E1E2E5"/>
                    <rect width="1.94073" height="23.0144" rx="0.970364" transform="matrix(0.866025 -0.5 -0.5 -0.866025 84.2083 211.115)" fill="#E1E2E5"/>
                    </g>
                    <rect x="110.735" y="86.3965" width="46.4836" height="109.618" rx="23.2418" fill="#E9EBF2"/>
                    <rect x="132.936" y="130.799" width="2.77514" height="171.365" rx="1.38757" fill="#E1E2E5"/>
                    <rect x="144.385" y="136.892" width="2.77514" height="23.3917" rx="1.38757" transform="rotate(30 144.385 136.892)" fill="#E1E2E5"/>
                    <rect width="2.77514" height="32.9094" rx="1.38757" transform="matrix(0.866025 -0.5 -0.5 -0.866025 133.705 174.252)" fill="#E1E2E5"/>
                    <path opacity="0.8" fill-rule="evenodd" clip-rule="evenodd" d="M172.795 60.7608C172.795 60.8448 172.794 60.9286 172.792 61.0121C178.01 61.1417 182.2 65.4123 182.2 70.6615C182.2 75.9097 178.012 80.1796 172.795 80.3108V80.3135H172.64C172.609 80.3138 172.579 80.314 172.548 80.314C172.517 80.314 172.486 80.3138 172.455 80.3135H153.49V80.3108C153.408 80.3129 153.326 80.314 153.243 80.314C147.912 80.314 143.59 75.9924 143.59 70.6615C143.59 65.3306 147.912 61.0091 153.243 61.0091C153.327 61.0091 153.41 61.0101 153.493 61.0123C153.491 60.9287 153.49 60.8449 153.49 60.7608C153.49 55.4299 157.812 51.1084 163.143 51.1084C168.474 51.1084 172.795 55.4299 172.795 60.7608Z" fill="white"/>
                    <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M350.583 89.9222C350.583 89.9644 350.582 90.0065 350.581 90.0485C353.212 90.114 355.325 92.2675 355.325 94.9144C355.325 97.5609 353.213 99.7141 350.583 99.7803V99.7823H340.848V99.7803C340.806 99.7814 340.765 99.7819 340.723 99.7819C338.035 99.7819 335.855 97.6027 335.855 94.9144C335.855 92.2262 338.035 90.047 340.723 90.047C340.765 90.047 340.807 90.0475 340.849 90.0486C340.848 90.0066 340.848 89.9644 340.848 89.9222C340.848 87.2339 343.027 85.0547 345.715 85.0547C348.403 85.0547 350.583 87.2339 350.583 89.9222Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M269.171 33.8631C269.171 25.2418 276.16 18.2529 284.781 18.2529C291.179 18.2529 296.678 22.1017 299.089 27.6101C300.64 26.5126 302.534 25.8677 304.579 25.8677C309.391 25.8677 313.368 29.4372 314.008 34.0725H317.578C321.946 34.0725 325.488 37.6137 325.488 41.9821C325.488 46.3505 321.946 49.8917 317.578 49.8917H270.753C266.385 49.8917 262.843 46.3505 262.843 41.9821C262.843 38.154 265.563 34.961 269.175 34.2298C269.173 34.1079 269.171 33.9857 269.171 33.8631Z" fill="white"/>
                    <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M161.991 137.791C161.991 134.274 164.842 131.423 168.359 131.423C170.968 131.423 173.212 132.993 174.195 135.24C174.828 134.792 175.601 134.529 176.435 134.529C178.397 134.529 180.019 135.985 180.281 137.875H181.737C183.519 137.875 184.964 139.32 184.964 141.102C184.964 142.884 183.519 144.328 181.737 144.328H162.636C160.854 144.328 159.41 142.884 159.41 141.102C159.41 139.54 160.519 138.238 161.993 137.939C161.991 137.89 161.991 137.84 161.991 137.791Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M386.212 34.9365C385.898 35.3089 385.695 35.7611 385.624 36.2425L376.399 99.0343C376.226 100.211 376.867 101.356 377.96 101.824L386.212 105.361C386.34 105.416 386.468 105.459 386.597 105.493C386.947 105.584 387.245 105.869 387.245 106.231V125.602C387.245 126.325 387.831 126.912 388.555 126.912C389.279 126.912 389.866 126.325 389.866 125.602V102.973C389.866 102.966 389.866 102.959 389.866 102.952L389.866 32.389C389.866 31.7776 389.102 31.4993 388.709 31.9672L386.212 34.9365Z" fill="#DCDCDC"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M386.212 33.625C385.898 33.9974 385.695 34.4496 385.624 34.931L376.399 97.7227C376.226 98.8991 376.867 100.044 377.96 100.513L386.212 104.05C386.34 104.104 386.468 104.148 386.597 104.181C386.947 104.272 387.245 104.558 387.245 104.919V124.29C387.245 125.014 387.831 125.6 388.555 125.6C389.279 125.6 389.866 125.014 389.866 124.29V101.662C389.866 101.655 389.866 101.648 389.866 101.641L389.866 31.0774C389.866 30.4661 389.102 30.1878 388.709 30.6557L386.212 33.625Z" fill="#EDEDED"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M311.908 172.132C312.387 172.217 312.88 172.167 313.332 171.987L372.324 148.581C373.43 148.142 374.101 147.015 373.96 145.834L372.897 136.919C372.881 136.781 372.854 136.648 372.819 136.52C372.722 136.171 372.82 135.77 373.134 135.59L389.909 125.904C390.536 125.542 390.751 124.741 390.389 124.114C390.027 123.487 389.225 123.273 388.599 123.635L356.821 141.981C356.815 141.985 356.809 141.989 356.802 141.992L307.874 170.241C307.345 170.547 307.486 171.347 308.088 171.454L311.908 172.132Z" fill="#DCDCDC"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M311.252 171.475C311.732 171.56 312.225 171.51 312.677 171.331L371.669 147.925C372.774 147.486 373.446 146.359 373.305 145.178L372.242 136.262C372.225 136.125 372.199 135.992 372.163 135.863C372.067 135.515 372.165 135.114 372.479 134.933L389.254 125.248C389.881 124.886 390.095 124.085 389.733 123.458C389.372 122.831 388.57 122.616 387.943 122.978L356.166 141.325C356.16 141.329 356.153 141.332 356.147 141.336L307.219 169.585C306.69 169.89 306.83 170.69 307.432 170.797L311.252 171.475Z" fill="#EDEDED"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M468.724 168.971C468.559 168.514 468.269 168.111 467.887 167.81L418.121 128.424C417.188 127.686 415.876 127.668 414.924 128.381L407.735 133.759C407.624 133.842 407.522 133.931 407.429 134.026C407.175 134.284 406.779 134.399 406.466 134.218L389.69 124.532C389.063 124.171 388.262 124.385 387.9 125.012C387.538 125.639 387.753 126.44 388.38 126.802L407.933 138.091C407.953 138.104 407.974 138.116 407.994 138.128L469.104 173.409C469.633 173.715 470.256 173.193 470.047 172.618L468.724 168.971Z" fill="#DCDCDC"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M468.724 167.66C468.558 167.202 468.269 166.8 467.887 166.498L418.121 127.113C417.188 126.375 415.876 126.357 414.924 127.069L407.735 132.448C407.624 132.531 407.522 132.62 407.429 132.715C407.175 132.972 406.779 133.087 406.466 132.907L389.69 123.221C389.063 122.859 388.262 123.074 387.9 123.701C387.538 124.327 387.753 125.129 388.38 125.491L407.933 136.78C407.953 136.792 407.974 136.804 407.994 136.816L469.104 172.098C469.633 172.404 470.256 171.882 470.047 171.307L468.724 167.66Z" fill="#EDEDED"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M391.924 129.597C393.796 128.797 395.107 126.939 395.107 124.775C395.107 121.88 392.761 119.533 389.866 119.533C386.971 119.533 384.624 121.88 384.624 124.775C384.624 126.939 385.935 128.797 387.807 129.597L377.416 303H402.315L391.924 129.597Z" fill="#DCDCDC"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M390.613 129.597C392.485 128.797 393.797 126.939 393.797 124.775C393.797 121.88 391.45 119.533 388.555 119.533C385.66 119.533 383.313 121.88 383.313 124.775C383.313 126.939 384.625 128.797 386.497 129.597L376.106 303H401.005L390.613 129.597Z" fill="#EDEDED"/>
                    <path d="M403.886 299.35H390.575C375.152 299.35 381.806 294.767 385.708 293.878C376.986 290.767 383.413 284.907 387.821 287.351C385.067 276.686 394.476 278.908 396.312 284.907C399.984 278.686 406.411 281.797 402.509 288.462C409.165 285.574 409.165 291.351 406.411 293.128C413.066 294.239 409.346 296.322 405.674 296.322C410.035 298.766 406.822 299.35 403.886 299.35Z" fill="#D7D8DE"/>
                    <path d="M345.476 299.35H332.165C316.743 299.35 323.396 294.767 327.298 293.878C318.577 290.767 325.003 284.907 329.411 287.351C326.657 276.686 336.067 278.908 337.903 284.907C341.575 278.686 348.001 281.797 344.099 288.462C350.755 285.574 350.755 291.351 348.001 293.128C354.657 294.239 350.937 296.322 347.265 296.322C351.625 298.766 348.412 299.35 345.476 299.35Z" fill="#D7D8DE"/>
                    <path d="M385.185 300.567H359.117C328.914 300.567 341.945 291.974 349.585 290.307C332.507 284.475 345.091 273.487 353.724 278.07C348.33 258.072 366.758 262.238 370.353 273.487C377.544 261.822 390.129 267.654 382.488 280.153C395.522 274.737 395.522 285.569 390.129 288.901C403.163 290.985 395.878 294.89 388.687 294.89C397.227 299.473 390.934 300.567 385.185 300.567Z" fill="#E1E1E6"/>
                    <path d="M332.696 276.023C332.522 274.809 332.013 273.589 330.978 272.752C333.398 272.827 334.319 273.486 335.322 274.468C337.744 276.842 336.447 279.741 335.026 279.276C333.605 278.812 332.876 277.281 332.696 276.023Z" fill="#E5E6ED"/>
                    <path d="M332.506 273.395C335.039 274.634 335.998 277.913 336.34 279.535L336.017 279.616C335.585 276.218 333.875 274.081 332.506 273.395C332.462 273.374 332.418 273.353 332.373 273.333C332.417 273.352 332.461 273.373 332.506 273.395Z" fill="#727785"/>
                    <path d="M334.81 274.283C335.228 274.805 335.101 275.606 335.01 275.988L334.933 275.973C335.184 275.2 335.034 274.568 334.81 274.283C334.803 274.274 334.795 274.265 334.788 274.256C334.795 274.264 334.803 274.273 334.81 274.283Z" fill="#727785"/>
                    <path d="M333.588 274.737C333.796 275.04 334.212 275.105 334.415 275.12L334.418 275.077C333.999 275.082 333.702 274.9 333.588 274.737C333.584 274.731 333.581 274.726 333.577 274.721C333.581 274.726 333.584 274.731 333.588 274.737Z" fill="#727785"/>
                    <path d="M335.807 275.487C336.076 276.099 335.747 276.84 335.561 277.187L335.49 277.152C335.932 276.47 335.95 275.821 335.807 275.487C335.802 275.476 335.797 275.466 335.792 275.455C335.797 275.465 335.802 275.476 335.807 275.487Z" fill="#727785"/>
                    <path d="M333.714 275.889C334.087 276.444 334.888 276.565 335.28 276.593L335.289 276.515C334.476 276.522 333.919 276.188 333.714 275.889C333.708 275.879 333.701 275.869 333.695 275.859C333.701 275.869 333.707 275.879 333.714 275.889Z" fill="#727785"/>
                    <path d="M333.699 277.164C334.219 277.973 335.298 278.175 335.825 278.23L335.834 278.118C334.744 278.098 333.985 277.601 333.699 277.164C333.69 277.151 333.681 277.136 333.672 277.122C333.681 277.136 333.689 277.15 333.699 277.164Z" fill="#727785"/>
                    <path d="M404.012 279.008C404.719 278.006 405.727 277.15 407.029 276.875C404.839 275.842 403.719 276.011 402.38 276.43C399.143 277.442 398.981 280.615 400.458 280.847C401.934 281.079 403.28 280.047 404.012 279.008Z" fill="#E5E6ED"/>
                    <path d="M405.376 276.754C402.556 276.706 400.212 279.191 399.17 280.48L399.42 280.699C401.351 277.868 403.845 276.742 405.376 276.754C405.424 276.755 405.473 276.756 405.522 276.759C405.474 276.756 405.425 276.754 405.376 276.754Z" fill="#727785"/>
                    <path d="M402.921 276.496C402.311 276.772 402.06 277.542 401.967 277.925L402.043 277.946C402.17 277.143 402.591 276.649 402.921 276.496C402.931 276.491 402.942 276.487 402.953 276.482C402.942 276.487 402.931 276.491 402.921 276.496Z" fill="#727785"/>
                    <path d="M403.802 277.458C403.479 277.633 403.079 277.501 402.891 277.423L402.908 277.383C403.279 277.578 403.626 277.551 403.802 277.458C403.808 277.455 403.813 277.451 403.819 277.448C403.813 277.451 403.808 277.455 403.802 277.458Z" fill="#727785"/>
                    <path d="M401.485 277.116C400.967 277.54 400.922 278.349 400.931 278.742L401.01 278.743C400.926 277.935 401.206 277.349 401.485 277.116C401.494 277.109 401.503 277.102 401.512 277.095C401.503 277.102 401.494 277.109 401.485 277.116Z" fill="#727785"/>
                    <path d="M403.166 278.426C402.582 278.751 401.813 278.494 401.451 278.341L401.478 278.267C402.199 278.643 402.847 278.599 403.166 278.426C403.176 278.42 403.186 278.414 403.196 278.408C403.187 278.414 403.177 278.42 403.166 278.426Z" fill="#727785"/>
                    <path d="M402.6 279.568C401.769 280.051 400.717 279.741 400.222 279.551L400.265 279.447C401.245 279.924 402.147 279.826 402.6 279.568C402.615 279.559 402.629 279.551 402.643 279.542C402.629 279.551 402.615 279.559 402.6 279.568Z" fill="#727785"/>
                    <path d="M376.135 259.101C376.22 257.877 376.639 256.623 377.61 255.714C375.202 255.965 374.331 256.689 373.403 257.741C371.159 260.284 372.664 263.081 374.047 262.515C375.431 261.948 376.047 260.368 376.135 259.101Z" fill="#E5E6ED"/>
                    <path d="M376.133 256.466C373.697 257.886 372.979 261.226 372.756 262.868L373.084 262.926C373.268 259.505 374.818 257.249 376.133 256.466C376.176 256.442 376.218 256.418 376.261 256.395C376.219 256.417 376.176 256.441 376.133 256.466Z" fill="#727785"/>
                    <path d="M373.9 257.518C373.521 258.069 373.705 258.858 373.824 259.233L373.9 259.213C373.593 258.46 373.697 257.819 373.9 257.518C373.906 257.508 373.913 257.499 373.92 257.489C373.913 257.498 373.906 257.508 373.9 257.518Z" fill="#727785"/>
                    <path d="M375.152 257.883C374.966 258.2 374.556 258.295 374.354 258.325L374.349 258.282C374.767 258.257 375.05 258.054 375.152 257.883C375.155 257.877 375.158 257.872 375.161 257.866C375.158 257.872 375.155 257.877 375.152 257.883Z" fill="#727785"/>
                    <path d="M372.994 258.792C372.77 259.423 373.151 260.138 373.362 260.47L373.43 260.43C372.94 259.782 372.875 259.136 372.994 258.792C372.997 258.781 373.001 258.771 373.006 258.76C373.001 258.77 372.997 258.781 372.994 258.792Z" fill="#727785"/>
                    <path d="M375.11 259.041C374.779 259.623 373.988 259.801 373.599 259.858L373.584 259.78C374.395 259.728 374.927 259.355 375.11 259.041C375.115 259.031 375.121 259.021 375.126 259.011C375.121 259.021 375.116 259.031 375.11 259.041Z" fill="#727785"/>
                    <path d="M375.218 260.311C374.757 261.155 373.696 261.435 373.175 261.528L373.158 261.417C374.243 261.318 374.964 260.767 375.218 260.311C375.226 260.296 375.233 260.282 375.241 260.267C375.234 260.281 375.226 260.296 375.218 260.311Z" fill="#727785"/>
                    <path d="M349.85 260.08C349.809 259.36 349.571 258.619 349.007 258.077C350.422 258.242 350.929 258.674 351.467 259.3C352.769 260.813 351.863 262.448 351.053 262.104C350.244 261.761 349.893 260.827 349.85 260.08Z" fill="#E5E6ED"/>
                    <path d="M349.87 258.531C351.293 259.385 351.691 261.355 351.81 262.323L351.617 262.354C351.534 260.34 350.638 259.002 349.87 258.531C349.845 258.517 349.821 258.502 349.795 258.488C349.82 258.502 349.845 258.516 349.87 258.531Z" fill="#727785"/>
                    <path d="M351.177 259.165C351.395 259.493 351.281 259.956 351.209 260.175L351.164 260.163C351.35 259.722 351.294 259.344 351.177 259.165C351.173 259.16 351.169 259.154 351.165 259.148C351.169 259.154 351.173 259.159 351.177 259.165Z" fill="#727785"/>
                    <path d="M350.437 259.371C350.544 259.559 350.785 259.618 350.903 259.637L350.907 259.612C350.661 259.594 350.496 259.473 350.437 259.371C350.435 259.368 350.434 259.365 350.432 259.361C350.434 259.365 350.435 259.368 350.437 259.371Z" fill="#727785"/>
                    <path d="M351.7 259.923C351.828 260.295 351.598 260.713 351.471 260.907L351.431 260.883C351.725 260.505 351.768 260.125 351.7 259.923C351.698 259.916 351.696 259.91 351.694 259.903C351.696 259.91 351.698 259.916 351.7 259.923Z" fill="#727785"/>
                    <path d="M350.454 260.053C350.644 260.398 351.108 260.508 351.337 260.545L351.346 260.499C350.869 260.463 350.559 260.239 350.454 260.053C350.45 260.047 350.447 260.041 350.444 260.035C350.447 260.041 350.45 260.047 350.454 260.053Z" fill="#727785"/>
                    <path d="M350.381 260.8C350.645 261.3 351.268 261.472 351.574 261.531L351.585 261.466C350.947 261.399 350.527 261.07 350.381 260.8C350.376 260.791 350.372 260.782 350.367 260.773C350.372 260.782 350.376 260.791 350.381 260.8Z" fill="#727785"/>
                    <path d="M91.9825 300.566H79.0669C64.1026 300.566 70.5587 296.308 74.3443 295.483C65.8824 292.593 72.1175 287.149 76.3947 289.419C73.7225 279.512 82.8525 281.576 84.6339 287.149C88.1968 281.369 94.432 284.259 90.6464 290.452C97.1042 287.768 97.1042 293.135 94.432 294.786C100.89 295.818 97.2806 297.753 93.7177 297.753C97.9487 300.024 94.8311 300.566 91.9825 300.566Z" fill="#EDEEF2"/>
                    <path d="M99.1458 299.964H92.5506C84.9093 299.964 88.206 297.693 90.1391 297.253C85.8181 295.712 89.002 292.808 91.1861 294.019C89.8216 288.735 94.4837 289.836 95.3934 292.808C97.2127 289.726 100.397 291.267 98.4636 294.57C101.761 293.139 101.761 296.001 100.397 296.881C103.694 297.432 101.851 298.464 100.032 298.464C102.192 299.675 100.6 299.964 99.1458 299.964Z" fill="#DFE0E6"/>
                    <path d="M69.6614 285.291C69.3112 284.795 68.8117 284.371 68.1668 284.235C69.2517 283.723 69.8067 283.806 70.4702 284.014C72.0739 284.516 72.1541 286.088 71.4225 286.202C70.6909 286.317 70.0242 285.806 69.6614 285.291Z" fill="#E5E6ED"/>
                    <path d="M68.9859 284.174C70.3828 284.151 71.5442 285.382 72.0605 286.02L71.9365 286.129C70.9801 284.727 69.7443 284.169 68.9859 284.174C68.9618 284.175 68.9376 284.176 68.9133 284.177C68.937 284.175 68.9612 284.175 68.9859 284.174Z" fill="#727785"/>
                    <path d="M70.2023 284.047C70.5044 284.183 70.6289 284.565 70.6749 284.755L70.6373 284.765C70.5742 284.367 70.3656 284.123 70.2023 284.047C70.1971 284.045 70.1918 284.042 70.1865 284.04C70.1917 284.042 70.197 284.044 70.2023 284.047Z" fill="#727785"/>
                    <path d="M69.7655 284.523C69.9255 284.61 70.124 284.545 70.217 284.506L70.2083 284.486C70.0246 284.583 69.8527 284.57 69.7655 284.523C69.7627 284.522 69.7599 284.52 69.7572 284.519C69.7599 284.52 69.7626 284.522 69.7655 284.523Z" fill="#727785"/>
                    <path d="M70.9137 284.353C71.1704 284.563 71.1923 284.964 71.188 285.159L71.1489 285.16C71.1905 284.759 71.052 284.469 70.9137 284.353C70.9092 284.35 70.9047 284.346 70.9001 284.343C70.9046 284.346 70.9091 284.35 70.9137 284.353Z" fill="#727785"/>
                    <path d="M70.0806 285.004C70.3702 285.165 70.751 285.038 70.9305 284.962L70.9168 284.925C70.5596 285.112 70.2386 285.09 70.0806 285.004C70.0756 285.001 70.0706 284.998 70.0657 284.995C70.0705 284.998 70.0754 285.001 70.0806 285.004Z" fill="#727785"/>
                    <path d="M70.3611 285.569C70.7727 285.809 71.2943 285.655 71.5393 285.561L71.5181 285.51C71.0328 285.746 70.5856 285.697 70.3611 285.569C70.354 285.565 70.3469 285.561 70.3398 285.557C70.3467 285.561 70.3537 285.565 70.3611 285.569Z" fill="#727785"/>
                    <path d="M99.7572 289.512C100.107 289.016 100.607 288.592 101.252 288.455C100.167 287.944 99.6119 288.027 98.9484 288.235C97.3446 288.736 97.2644 290.308 97.996 290.423C98.7276 290.538 99.3943 290.027 99.7572 289.512Z" fill="#E5E6ED"/>
                    <path d="M100.433 288.395C99.0358 288.372 97.8743 289.602 97.3581 290.241L97.482 290.35C98.4384 288.947 99.6742 288.389 100.433 288.395C100.457 288.396 100.481 288.396 100.505 288.397C100.482 288.396 100.457 288.395 100.433 288.395Z" fill="#727785"/>
                    <path d="M99.2163 288.268C98.9142 288.404 98.7897 288.786 98.7437 288.975L98.7813 288.986C98.8443 288.588 99.0529 288.343 99.2163 288.268C99.2215 288.265 99.2267 288.263 99.2321 288.261C99.2268 288.263 99.2216 288.265 99.2163 288.268Z" fill="#727785"/>
                    <path d="M99.6531 288.744C99.493 288.831 99.2945 288.766 99.2015 288.727L99.2102 288.707C99.3939 288.804 99.5658 288.79 99.6531 288.744C99.6558 288.742 99.6586 288.741 99.6613 288.739C99.6587 288.741 99.6559 288.742 99.6531 288.744Z" fill="#727785"/>
                    <path d="M98.5049 288.574C98.2482 288.784 98.2263 289.185 98.2306 289.38L98.2697 289.38C98.2281 288.98 98.3666 288.689 98.5049 288.574C98.5094 288.571 98.5139 288.567 98.5184 288.563C98.5139 288.567 98.5094 288.57 98.5049 288.574Z" fill="#727785"/>
                    <path d="M99.3379 289.224C99.0483 289.386 98.6675 289.258 98.4879 289.183L98.5017 289.146C98.8588 289.332 99.1799 289.311 99.3379 289.224C99.3429 289.222 99.3478 289.219 99.3528 289.216C99.348 289.219 99.343 289.222 99.3379 289.224Z" fill="#727785"/>
                    <path d="M99.0575 289.79C98.6459 290.03 98.1243 289.876 97.8793 289.782L97.9005 289.73C98.3858 289.967 98.833 289.918 99.0575 289.79C99.0646 289.786 99.0717 289.782 99.0787 289.777C99.0719 289.782 99.0648 289.786 99.0575 289.79Z" fill="#727785"/>
                    <path d="M86.2929 282.433C86.3351 281.826 86.5427 281.205 87.0237 280.754C85.8306 280.879 85.3992 281.238 84.9392 281.759C83.8275 283.019 84.5732 284.405 85.2585 284.124C85.9438 283.843 86.2491 283.061 86.2929 282.433Z" fill="#E5E6ED"/>
                    <path d="M86.292 281.127C85.085 281.831 84.7292 283.485 84.6186 284.299L84.7809 284.328C84.8723 282.633 85.6402 281.515 86.292 281.127C86.3128 281.115 86.3339 281.103 86.3552 281.092C86.3343 281.103 86.3132 281.115 86.292 281.127Z" fill="#727785"/>
                    <path d="M85.1853 281.648C84.9977 281.921 85.0891 282.312 85.1478 282.498L85.1855 282.488C85.0333 282.115 85.0848 281.797 85.1853 281.648C85.1886 281.643 85.1919 281.638 85.1953 281.634C85.1919 281.638 85.1886 281.643 85.1853 281.648Z" fill="#727785"/>
                    <path d="M85.8056 281.83C85.7138 281.987 85.5102 282.034 85.4105 282.049L85.4078 282.028C85.6149 282.015 85.7551 281.914 85.8056 281.83C85.8072 281.827 85.8088 281.824 85.8103 281.821C85.8088 281.824 85.8073 281.827 85.8056 281.83Z" fill="#727785"/>
                    <path d="M84.7363 282.28C84.6253 282.592 84.8143 282.947 84.919 283.111L84.9528 283.091C84.7097 282.77 84.6776 282.45 84.7363 282.28C84.7382 282.274 84.7402 282.269 84.7423 282.264C84.7402 282.269 84.7382 282.274 84.7363 282.28Z" fill="#727785"/>
                    <path d="M85.7848 282.403C85.6207 282.691 85.229 282.779 85.0361 282.807L85.029 282.769C85.4309 282.743 85.6943 282.558 85.7848 282.403C85.7876 282.398 85.7904 282.393 85.7931 282.388C85.7905 282.393 85.7877 282.398 85.7848 282.403Z" fill="#727785"/>
                    <path d="M85.8382 283.033C85.6102 283.451 85.0844 283.59 84.826 283.636L84.8176 283.581C85.3552 283.532 85.7125 283.259 85.8382 283.033C85.8421 283.025 85.846 283.018 85.8497 283.011C85.8461 283.018 85.8423 283.025 85.8382 283.033Z" fill="#727785"/>
                    <path d="M73.5787 282.315C73.5583 281.958 73.4407 281.591 73.161 281.322C73.862 281.404 74.1132 281.618 74.38 281.928C75.025 282.678 74.5761 283.488 74.1749 283.318C73.7738 283.147 73.5999 282.685 73.5787 282.315Z" fill="#E5E6ED"/>
                    <path d="M73.5887 281.547C74.2937 281.97 74.491 282.946 74.5501 283.425L74.4544 283.441C74.413 282.443 73.9693 281.78 73.5887 281.547C73.5766 281.539 73.5642 281.532 73.5518 281.525C73.564 281.532 73.5763 281.539 73.5887 281.547Z" fill="#727785"/>
                    <path d="M74.2361 281.862C74.3445 282.024 74.2879 282.253 74.252 282.362L74.2299 282.356C74.3221 282.138 74.2942 281.95 74.2361 281.862C74.2342 281.859 74.2323 281.856 74.2303 281.854C74.2323 281.856 74.2342 281.859 74.2361 281.862Z" fill="#727785"/>
                    <path d="M73.8697 281.963C73.9226 282.056 74.0421 282.085 74.1007 282.095L74.1024 282.082C73.9806 282.073 73.8989 282.013 73.8697 281.963C73.8688 281.961 73.8679 281.96 73.8671 281.958C73.8679 281.96 73.8688 281.961 73.8697 281.963Z" fill="#727785"/>
                    <path d="M74.4956 282.236C74.5586 282.421 74.4448 282.628 74.3821 282.724L74.3623 282.712C74.5077 282.525 74.5289 282.337 74.4956 282.236C74.4945 282.233 74.4934 282.23 74.4922 282.227C74.4934 282.23 74.4945 282.233 74.4956 282.236Z" fill="#727785"/>
                    <path d="M73.8778 282.301C73.9722 282.472 74.2021 282.526 74.3153 282.544L74.3198 282.522C74.0835 282.504 73.9299 282.393 73.8778 282.301C73.8762 282.298 73.8746 282.295 73.873 282.292C73.8745 282.295 73.8761 282.298 73.8778 282.301Z" fill="#727785"/>
                    <path d="M73.8418 282.671C73.9729 282.919 74.2813 283.004 74.433 283.033L74.4383 283.001C74.1224 282.968 73.9141 282.805 73.8418 282.671C73.8396 282.667 73.8374 282.663 73.8352 282.658C73.8373 282.662 73.8395 282.667 73.8418 282.671Z" fill="#727785"/>
                    <path d="M95.8397 286.535C95.8602 286.178 95.9778 285.811 96.2575 285.543C95.5564 285.625 95.3052 285.839 95.0384 286.149C94.3935 286.898 94.8424 287.708 95.2435 287.538C95.6447 287.368 95.8186 286.905 95.8397 286.535Z" fill="#E5E6ED"/>
                    <path d="M95.8297 285.767C95.1247 286.19 94.9275 287.166 94.8684 287.646L94.9641 287.662C95.0055 286.664 95.4491 286.001 95.8297 285.767C95.8419 285.76 95.8542 285.753 95.8667 285.746C95.8545 285.753 95.8421 285.76 95.8297 285.767Z" fill="#727785"/>
                    <path d="M95.1824 286.082C95.074 286.244 95.1306 286.473 95.1665 286.582L95.1886 286.576C95.0964 286.357 95.1244 286.17 95.1824 286.082C95.1843 286.079 95.1862 286.076 95.1882 286.073C95.1862 286.076 95.1843 286.079 95.1824 286.082Z" fill="#727785"/>
                    <path d="M95.5487 286.184C95.4958 286.277 95.3764 286.306 95.3178 286.315L95.3161 286.303C95.4379 286.294 95.5196 286.234 95.5487 286.184C95.5496 286.182 95.5505 286.18 95.5514 286.179C95.5506 286.18 95.5497 286.182 95.5487 286.184Z" fill="#727785"/>
                    <path d="M94.9228 286.457C94.8598 286.641 94.9736 286.848 95.0363 286.944L95.0561 286.933C94.9107 286.745 94.8895 286.557 94.9228 286.457C94.9239 286.454 94.925 286.45 94.9262 286.447C94.925 286.45 94.9239 286.454 94.9228 286.457Z" fill="#727785"/>
                    <path d="M95.5406 286.522C95.4462 286.692 95.2163 286.747 95.1031 286.765L95.0986 286.743C95.3349 286.725 95.4885 286.614 95.5406 286.522C95.5422 286.519 95.5438 286.516 95.5453 286.513C95.5439 286.516 95.5423 286.519 95.5406 286.522Z" fill="#727785"/>
                    <path d="M95.5766 286.892C95.4455 287.14 95.1372 287.225 94.9855 287.254L94.9802 287.222C95.2961 287.189 95.5043 287.026 95.5766 286.892C95.5789 286.888 95.5811 286.883 95.5833 286.879C95.5812 286.883 95.579 286.888 95.5766 286.892Z" fill="#727785"/>
                    <path d="M141.874 300.566H128.958C113.994 300.566 120.45 296.308 124.236 295.483C115.774 292.593 122.009 287.149 126.286 289.419C123.614 279.512 132.744 281.576 134.526 287.149C138.088 281.369 144.324 284.259 140.538 290.452C146.996 287.768 146.996 293.135 144.324 294.786C150.781 295.818 147.172 297.753 143.609 297.753C147.84 300.024 144.723 300.566 141.874 300.566Z" fill="#EDEEF2"/>
                    <path d="M149.037 299.964H142.442C134.801 299.964 138.098 297.693 140.031 297.253C135.71 295.712 138.894 292.808 141.078 294.019C139.713 288.735 144.375 289.836 145.285 292.808C147.104 289.726 150.288 291.267 148.355 294.57C151.653 293.139 151.653 296.001 150.288 296.881C153.586 297.432 151.743 298.464 149.924 298.464C152.084 299.675 150.492 299.964 149.037 299.964Z" fill="#DFE0E6"/>
                    <path d="M119.553 285.291C119.203 284.795 118.703 284.371 118.058 284.235C119.143 283.723 119.698 283.806 120.362 284.014C121.965 284.516 122.046 286.088 121.314 286.202C120.582 286.317 119.916 285.806 119.553 285.291Z" fill="#E5E6ED"/>
                    <path d="M118.878 284.174C120.274 284.151 121.436 285.382 121.952 286.02L121.828 286.129C120.872 284.727 119.636 284.169 118.878 284.174C118.853 284.175 118.829 284.176 118.805 284.177C118.829 284.175 118.853 284.175 118.878 284.174Z" fill="#727785"/>
                    <path d="M120.094 284.047C120.396 284.183 120.52 284.565 120.566 284.755L120.529 284.765C120.466 284.367 120.257 284.123 120.094 284.047C120.089 284.045 120.083 284.042 120.078 284.04C120.083 284.042 120.089 284.044 120.094 284.047Z" fill="#727785"/>
                    <path d="M119.657 284.523C119.817 284.61 120.016 284.545 120.109 284.506L120.1 284.486C119.916 284.583 119.744 284.57 119.657 284.523C119.654 284.522 119.652 284.52 119.649 284.519C119.652 284.52 119.654 284.522 119.657 284.523Z" fill="#727785"/>
                    <path d="M120.805 284.353C121.062 284.563 121.084 284.964 121.08 285.159L121.04 285.16C121.082 284.759 120.944 284.469 120.805 284.353C120.801 284.35 120.796 284.346 120.792 284.343C120.796 284.346 120.801 284.35 120.805 284.353Z" fill="#727785"/>
                    <path d="M119.972 285.004C120.262 285.165 120.643 285.038 120.822 284.962L120.808 284.925C120.451 285.112 120.13 285.09 119.972 285.004C119.967 285.001 119.962 284.998 119.957 284.995C119.962 284.998 119.967 285.001 119.972 285.004Z" fill="#727785"/>
                    <path d="M120.253 285.569C120.664 285.809 121.186 285.655 121.431 285.561L121.41 285.51C120.924 285.746 120.477 285.697 120.253 285.569C120.245 285.565 120.238 285.561 120.231 285.557C120.238 285.561 120.245 285.565 120.253 285.569Z" fill="#727785"/>
                    <path d="M149.649 289.512C149.999 289.016 150.498 288.592 151.143 288.455C150.058 287.944 149.503 288.027 148.84 288.235C147.236 288.736 147.156 290.308 147.888 290.423C148.619 290.538 149.286 290.027 149.649 289.512Z" fill="#E5E6ED"/>
                    <path d="M150.324 288.395C148.927 288.372 147.766 289.602 147.25 290.241L147.374 290.35C148.33 288.947 149.566 288.389 150.324 288.395C150.348 288.396 150.372 288.396 150.397 288.397C150.373 288.396 150.349 288.395 150.324 288.395Z" fill="#727785"/>
                    <path d="M149.108 288.268C148.806 288.404 148.681 288.786 148.635 288.975L148.673 288.986C148.736 288.588 148.945 288.343 149.108 288.268C149.113 288.265 149.118 288.263 149.124 288.261C149.118 288.263 149.113 288.265 149.108 288.268Z" fill="#727785"/>
                    <path d="M149.545 288.744C149.385 288.831 149.186 288.766 149.093 288.727L149.102 288.707C149.286 288.804 149.457 288.79 149.545 288.744C149.547 288.742 149.55 288.741 149.553 288.739C149.55 288.741 149.548 288.742 149.545 288.744Z" fill="#727785"/>
                    <path d="M148.397 288.574C148.14 288.784 148.118 289.185 148.122 289.38L148.161 289.38C148.12 288.98 148.258 288.689 148.397 288.574C148.401 288.571 148.405 288.567 148.41 288.563C148.406 288.567 148.401 288.57 148.397 288.574Z" fill="#727785"/>
                    <path d="M149.229 289.224C148.94 289.386 148.559 289.258 148.38 289.183L148.393 289.146C148.75 289.332 149.071 289.311 149.229 289.224C149.234 289.222 149.239 289.219 149.244 289.216C149.24 289.219 149.235 289.222 149.229 289.224Z" fill="#727785"/>
                    <path d="M148.949 289.79C148.537 290.03 148.016 289.876 147.771 289.782L147.792 289.73C148.277 289.967 148.725 289.918 148.949 289.79C148.956 289.786 148.963 289.782 148.97 289.777C148.964 289.782 148.956 289.786 148.949 289.79Z" fill="#727785"/>
                    <path d="M136.184 282.433C136.227 281.826 136.434 281.205 136.915 280.754C135.722 280.879 135.291 281.238 134.831 281.759C133.719 283.019 134.465 284.405 135.15 284.124C135.835 283.843 136.141 283.061 136.184 282.433Z" fill="#E5E6ED"/>
                    <path d="M136.184 281.127C134.977 281.831 134.621 283.485 134.51 284.299L134.673 284.328C134.764 282.633 135.532 281.515 136.184 281.127C136.204 281.115 136.226 281.103 136.247 281.092C136.226 281.103 136.205 281.115 136.184 281.127Z" fill="#727785"/>
                    <path d="M135.077 281.648C134.889 281.921 134.981 282.312 135.039 282.498L135.077 282.488C134.925 282.115 134.976 281.797 135.077 281.648C135.08 281.643 135.084 281.638 135.087 281.634C135.084 281.638 135.08 281.643 135.077 281.648Z" fill="#727785"/>
                    <path d="M135.697 281.83C135.605 281.987 135.402 282.034 135.302 282.049L135.299 282.028C135.507 282.015 135.647 281.914 135.697 281.83C135.699 281.827 135.7 281.824 135.702 281.821C135.701 281.824 135.699 281.827 135.697 281.83Z" fill="#727785"/>
                    <path d="M134.628 282.28C134.517 282.592 134.706 282.947 134.811 283.111L134.844 283.091C134.601 282.77 134.569 282.45 134.628 282.28C134.63 282.274 134.632 282.269 134.634 282.264C134.632 282.269 134.63 282.274 134.628 282.28Z" fill="#727785"/>
                    <path d="M135.676 282.403C135.512 282.691 135.121 282.779 134.928 282.807L134.921 282.769C135.322 282.743 135.586 282.558 135.676 282.403C135.679 282.398 135.682 282.393 135.685 282.388C135.682 282.393 135.679 282.398 135.676 282.403Z" fill="#727785"/>
                    <path d="M135.73 283.033C135.502 283.451 134.976 283.59 134.718 283.636L134.709 283.581C135.247 283.532 135.604 283.259 135.73 283.033C135.734 283.025 135.738 283.018 135.741 283.011C135.738 283.018 135.734 283.025 135.73 283.033Z" fill="#727785"/>
                    <path d="M123.47 282.315C123.45 281.958 123.332 281.591 123.053 281.322C123.754 281.404 124.005 281.618 124.272 281.928C124.917 282.678 124.468 283.488 124.067 283.318C123.665 283.147 123.492 282.685 123.47 282.315Z" fill="#E5E6ED"/>
                    <path d="M123.48 281.547C124.185 281.97 124.383 282.946 124.442 283.425L124.346 283.441C124.305 282.443 123.861 281.78 123.48 281.547C123.468 281.539 123.456 281.532 123.443 281.525C123.456 281.532 123.468 281.539 123.48 281.547Z" fill="#727785"/>
                    <path d="M124.128 281.862C124.236 282.024 124.18 282.253 124.144 282.362L124.121 282.356C124.214 282.138 124.186 281.95 124.128 281.862C124.126 281.859 124.124 281.856 124.122 281.854C124.124 281.856 124.126 281.859 124.128 281.862Z" fill="#727785"/>
                    <path d="M123.761 281.963C123.814 282.056 123.934 282.085 123.992 282.095L123.994 282.082C123.872 282.073 123.791 282.013 123.761 281.963C123.76 281.961 123.76 281.96 123.759 281.958C123.76 281.96 123.76 281.961 123.761 281.963Z" fill="#727785"/>
                    <path d="M124.387 282.236C124.45 282.421 124.337 282.628 124.274 282.724L124.254 282.712C124.399 282.525 124.421 282.337 124.387 282.236C124.386 282.233 124.385 282.23 124.384 282.227C124.385 282.23 124.386 282.233 124.387 282.236Z" fill="#727785"/>
                    <path d="M123.77 282.301C123.864 282.472 124.094 282.526 124.207 282.544L124.212 282.522C123.975 282.504 123.822 282.393 123.77 282.301C123.768 282.298 123.766 282.295 123.765 282.292C123.766 282.295 123.768 282.298 123.77 282.301Z" fill="#727785"/>
                    <path d="M123.733 282.671C123.865 282.919 124.173 283.004 124.325 283.033L124.33 283.001C124.014 282.968 123.806 282.805 123.733 282.671C123.731 282.667 123.729 282.663 123.727 282.658C123.729 282.662 123.731 282.667 123.733 282.671Z" fill="#727785"/>
                    <path d="M145.731 286.535C145.752 286.178 145.869 285.811 146.149 285.543C145.448 285.625 145.197 285.839 144.93 286.149C144.285 286.898 144.734 287.708 145.135 287.538C145.536 287.368 145.71 286.905 145.731 286.535Z" fill="#E5E6ED"/>
                    <path d="M145.721 285.767C145.016 286.19 144.819 287.166 144.76 287.646L144.856 287.662C144.897 286.664 145.341 286.001 145.721 285.767C145.734 285.76 145.746 285.753 145.758 285.746C145.746 285.753 145.734 285.76 145.721 285.767Z" fill="#727785"/>
                    <path d="M145.074 286.082C144.966 286.244 145.022 286.473 145.058 286.582L145.08 286.576C144.988 286.357 145.016 286.17 145.074 286.082C145.076 286.079 145.078 286.076 145.08 286.073C145.078 286.076 145.076 286.079 145.074 286.082Z" fill="#727785"/>
                    <path d="M145.44 286.184C145.387 286.277 145.268 286.306 145.209 286.315L145.208 286.303C145.329 286.294 145.411 286.234 145.44 286.184C145.441 286.182 145.442 286.18 145.443 286.179C145.442 286.18 145.441 286.182 145.44 286.184Z" fill="#727785"/>
                    <path d="M144.814 286.457C144.751 286.641 144.865 286.848 144.928 286.944L144.948 286.933C144.802 286.745 144.781 286.557 144.814 286.457C144.815 286.454 144.817 286.45 144.818 286.447C144.817 286.45 144.815 286.454 144.814 286.457Z" fill="#727785"/>
                    <path d="M145.432 286.522C145.338 286.692 145.108 286.747 144.995 286.765L144.99 286.743C145.226 286.725 145.38 286.614 145.432 286.522C145.434 286.519 145.435 286.516 145.437 286.513C145.435 286.516 145.434 286.519 145.432 286.522Z" fill="#727785"/>
                    <path d="M145.468 286.892C145.337 287.14 145.029 287.225 144.877 287.254L144.872 287.222C145.188 287.189 145.396 287.026 145.468 286.892C145.47 286.888 145.473 286.883 145.475 286.879C145.473 286.883 145.471 286.888 145.468 286.892Z" fill="#727785"/>
                    <rect x="239.723" y="141.157" width="9.73494" height="158.193" fill="#20242E"/>
                    <rect x="239.723" y="141.157" width="7.30121" height="158.193" fill="#27304C"/>
                    <path d="M275.884 55.4445C279.911 55.4445 283.631 57.5926 285.645 61.0796L316.329 114.227C318.343 117.714 318.343 122.01 316.329 125.497L285.645 178.645C283.631 182.132 279.911 184.28 275.884 184.28L214.515 184.28C210.488 184.28 206.768 182.132 204.755 178.645L174.07 125.497C172.057 122.01 172.057 117.714 174.07 114.227L204.755 61.0796C206.768 57.5926 210.488 55.4445 214.515 55.4445L275.884 55.4445Z" fill="#2C91FF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M189.331 151.931C236.266 146.833 272.797 107.075 272.797 58.7844C272.797 57.6667 272.777 56.5536 272.739 55.4453L214.515 55.4453C210.488 55.4453 206.768 57.5934 204.755 61.0804L174.07 114.228C172.057 117.715 172.057 122.011 174.07 125.498L189.331 151.931Z" fill="white" fill-opacity="0.15"/>
                    <path d="M271.895 63.4593C274.845 63.4593 277.57 65.0331 279.045 67.5879L306.496 115.134C307.971 117.689 307.971 120.836 306.496 123.391L279.045 170.937C277.57 173.492 274.845 175.065 271.895 175.065L216.993 175.065C214.043 175.065 211.317 173.492 209.842 170.937L182.392 123.391C180.917 120.836 180.917 117.689 182.392 115.134L209.842 67.5879C211.317 65.0331 214.043 63.4593 216.993 63.4593L271.895 63.4593Z" stroke="white" stroke-width="3.6506"/>
                    <path d="M230.019 130.092V124.494H227.294V116.609H220.917V124.494H214.444L224.033 101.861H217.656L208.067 124.494V130.092H220.917V135.446H227.294V130.092H230.019Z" fill="white"/>
                    <path d="M236.614 133.11C238.269 135.057 240.8 135.982 244.207 135.982C247.615 135.982 250.097 135.008 251.752 133.061C253.407 131.114 254.234 128.242 254.234 124.397V112.52C254.234 108.821 253.407 106.047 251.752 104.148C250.097 102.299 247.615 101.325 244.207 101.325C240.898 101.325 238.415 102.299 236.712 104.197C235.008 106.095 234.18 108.87 234.18 112.52V124.397C234.18 128.291 234.959 131.163 236.614 133.11ZM247.079 128.972C246.592 129.946 245.619 130.433 244.207 130.433C242.796 130.433 241.822 129.946 241.336 128.972C240.849 127.999 240.605 126.295 240.605 123.862V113.445C240.605 111.06 240.849 109.357 241.336 108.383C241.822 107.41 242.747 106.923 244.207 106.923C245.619 106.923 246.592 107.41 247.079 108.383C247.566 109.357 247.858 111.06 247.858 113.445V123.862C247.858 126.295 247.566 127.999 247.079 128.972Z" fill="white"/>
                    <path d="M280.31 130.092V124.494H277.585V116.609H271.208V124.494H264.734L274.323 101.861H267.947L258.358 124.494V130.092H271.208V135.446H277.585V130.092H280.31Z" fill="white"/>
                    <path d="M176.88 206.868H188.896L216.206 292.074H149.57L176.88 206.868Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M176.88 206.869H188.896L199.925 241.279H165.851L176.88 206.869ZM162.7 251.11L158.673 263.674H207.103L203.076 251.11H162.7ZM155.522 273.505H210.254L216.206 292.075H149.57L155.522 273.505Z" fill="#2C91FF"/>
                    <rect x="132.639" y="292.074" width="100.499" height="7.64666" fill="#2C91FF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M213.58 283.882L216.206 292.075H149.57L152.196 283.882H213.58Z" fill="black" fill-opacity="0.2"/>
                    <mask id="mask0_12400_280043" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="132" y="206" width="102" height="94">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M176.88 206.869H188.896L199.925 241.279H165.851L176.88 206.869ZM162.7 251.11L158.673 263.674H207.103L203.076 251.11H162.7ZM155.522 273.505H210.254L216.206 292.075H149.57L155.522 273.505Z" fill="#2F63FF"/>
                    <rect x="132.639" y="292.074" width="100.499" height="7.64666" fill="#2F63FF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M213.58 283.882L216.206 292.075H149.57L152.196 283.882H213.58Z" fill="black" fill-opacity="0.2"/>
                </mask>
                <g mask="url(#mask0_12400_280043)">
                <circle cx="119.53" cy="186.113" r="102.138" fill="white" fill-opacity="0.2"/>
                </g>
                <path d="M2.43372 300.566H488.434" stroke="#27304C" stroke-width="4.86747" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="title-text">
                Sorry, This Page Isn't Available
            </div>
            <div class="details-text">
                The link or the public handle may be incorrect or the public handle may not exist.<br>
                Check to see if the link you're trying to open is correct.
            </div>
            <button class="btn-go-back" @click="goBack()">
                Go Back
            </button>
            </div>
   </div>
</template>

<script>
    export default{
        name: "NotFound",
        methods:{
            goBack(){
                window.location = window.location.origin;
            }
        }
    }
</script>
<style scoped>

    .container-404 {
        position: relative;
        height: 100vh;
    }

    .centered-element {
        margin: 0;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
    .title-text{
        font-family: RubikRegular;
        color: #27304C;
        font-weight: 600;
        font-size: 18px;
        margin-top: 24px;
        margin-bottom: 14px;
    }
    .details-text{
        font-family: RubikRegular;
        color: #6D6D6D;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
    }
    .btn-go-back{
        background: #2C91FF;
        border-radius: 2px;
        width: 87px;
        height: 40px;
        color: white;
        font-family: RubikRegular;
        border:1px solid #2C91FF;
        margin-top: 40px;
    }
</style>