
const Module = () => import("./Module.vue");
const siteLocation = ((window.location.hostname == 'axpara.co.uk') ? 'uk': 'sg')   
const moduleRoute = {
    path: '/client',
    name: 'Client',
    meta: { isClientAccess: true, access: 'client' },
    component: Module,
        beforeEnter: (to, from, next) => {
            // if (window.location.hostname === 'axpara.com') {
            //     window.location.href = window.location.href.replace('axpara.com', 'axparasolutions.com');
            // } 
            if(to?.name == 'EmployerLandingPage'){
                if(to?.query?.country == 'uk' || siteLocation == 'uk'){
                    if(to?.query?.country == 'uk'){           
                        next({ path: '/client/home', query:{country:'uk'}});
                    }else{
                        next({ path: '/client/home' });
                    }   
                }else{
                     next();     
                }   
            }else{
                next();     
            }
            
        },
         children: [             
        {
            path: 'v2',
            name: 'ClientVersion2',
            meta: { guest: true, access: 'client' },
            children: [{
                    path: 'order-details/:id',                  
                    name: 'OrderDetails',
                    meta: { requiresAuth: true, isClientAccess: true, access: 'client', location: 'Any', menuId: 3},
                    component: () =>
                        import ('./views/client/myorder/OrderDetails.vue')
                },
                // {
                //     path: 'dashboard/persona1',
                //     name: 'Persona1',
                //     meta: { requiresAuth: true, isClientAccess: true, access: 'client', location: 'Any' },
                //     component: () =>
                //         import ('./views/client/dashboard/Persona1.vue')
                // },
                // {
                //     path: 'dashboard/persona2',
                //     name: 'Persona2',
                //     meta: { requiresAuth: true, isClientAccess: true, access: 'client', location: 'Any' },
                //     component: () =>
                //         import ('./views/client/dashboard/Persona2.vue')
                // },
                // {
                //     path: 'dashboard/persona3',
                //     name: 'Persona3',
                //     meta: { requiresAuth: true, isClientAccess: true, access: 'client', location: 'Any' },
                //     component: () =>
                //         import ('./views/client/dashboard/Persona3.vue')
                // },
                {
                    path: 'my-talents',
                    name: 'MyTalent',
                    meta: { requiresAuth: false, isClientAccess: true, access: 'client', location: 'Any' },
                    component: () =>
                        import ('./views/client/MyTalent.vue')
                },
                // {
                //     path: 'skillscanner',
                //     name: 'SkillScanner',
                //     meta: { requiresAuth: true, isClientAccess: true, access: 'client', location: 'Any' },
                //     component: () =>
                //         import ('./views/client/SkillScanner.vue')
                // },
                {
                    path: 'my-orders',
                    meta: { requiresAuth: false, isClientAccess: true, access: 'client', location: 'Any' },
                    name: 'MyOrders',
                    component: () =>
                        import ('./views/client/MyOrder.vue')
                },
                {
                    path: 'my-interview',
                    name: 'MyInterview',
                    meta: { requiresAuth: false, isClientAccess: true, access: 'client', location: 'Any' },
                    component: () =>
                        import ('./views/client/myinterview/MyInterview.vue')
                },
                {
                    path: 'calendar',
                    name: 'Calendar',
                    meta: { requiresAuth: true, isClientAccess: true, access: 'client', location: 'Any' },
                    component: () =>
                        import ('./views/client/myinterview/Calendar.vue')
                },
                {
                    path: 'candidate-db',
                    name: 'CandidateDatabase',
                    meta: { requiresAuth: true, isClientAccess: true, access: 'client', location: 'Any' },
                    component: () =>
                        import ('./views/client/candidatedatabase/CandidateDB.vue')
                },
                {
                    path: 'my-account',
                    name: 'MyAccount',
                    meta: { requiresAuth: false, isClientAccess: true, access: 'client', location: 'Any' },
                    component: () =>
                        import ('./views/client/MyAccount.vue')
                },
            ]
        },
        {
            path: 'auth/verify/:token',
            name: 'CPVerifyToken',
            meta: { guest: true, access: 'client'},
            component: () =>
            import ('./views/authentication/CPVerifyLogin.vue')
        },
        {
            path: 'auth/logout',
            name: 'CPLogout',
            meta: { guest: true, access: 'client'},
            component: () =>
            import ('./views/authentication/CPLogout.vue')
        },
        {
            path: '',
            name: 'EmployerLandingPage',
            meta: { guest: true, access: 'client' },
            component: () =>
                import ('./views/employer/CandidateDatabaseTable.vue')
        },
        {
            path: 'about-axpara',
            name: 'AboutAxpara',
            meta: { guest: true, access: 'client' },
            component: () =>
                import ('./views/employer/AboutAxpara.vue')
        },
        {
            path: 'candidate-db-table',
            name: 'CandidateDatabaseTable',
            meta: { guest: true, access: 'client' },
            component: () =>
                import ('./views/employer/CandidateDatabaseTable.vue')
        },
        {
            path: 'home',
            name: 'UKHome',
            meta: { guest: true, access: 'client' },
            component: () =>
                import ('./views/ukcomponent/UkHome.vue')
        },
    ],
}
 
export default router => {
    router.addRoute(moduleRoute);
};